html, body {
}

* {
  box-sizing: border-box;
}

h1, h2 {
  margin: 0;
}

a {
  text-decoration: none;
}

.app {
  color: white;
  background-color: #26333F;
  background-image: url('../src/assets/img/fieldbg.jpg');
  min-height: 100vh;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;

  &.so-wild {
    background-image: url('../src/assets/img/360still.png');
    background-repeat: repeat;
    background-size: 60%;
  }

  &:after {
    content: '';
    position: absolute;
    right:0;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(12,12,12,0.7);
  }
}

.component {
  z-index: 1;
  position: relative;
}

.app_wrapper {
  display: block;
}

.cover, 
.info {
  width: 100%;
  max-width: 100%;
}

.cover {
  padding: 10px;
}

.info {
  padding: 30px 20px;
}

.heading {
  text-transform: capitalize;
  padding-bottom: 30px;

  &_title {
    letter-spacing: 0.5px;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.4;
  }

  &_subtitle {
    letter-spacing: -0.5px;
    font-size: 34px;
    font-weight: 700;
  }
}

.cta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid rgba(255,255,255,0.15);
  padding-top: 30px;
}

.btn {
  color: white;
  padding: 10px 26px;
  background: #1DB954;
  border-radius: 30px;
  letter-spacing: 0.3px;
  font-weight: 500;
  font-size: 18px;
  transition: 0.3s;

  &:hover,
  &:focus {
    background: white;
    color: black;
  }
}

@media screen and (min-width: 400px) {
  .info {
    padding: 40px 50px;
  }
}

@media screen and (min-width: 500px) {
  .app {
        // margin: 0 auto;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }

  .app_wrapper {
    max-width: 480px;
  }

  .cover {
    width: auto;
    height: auto;
    max-width: 550px;
  }

  .btn {
    padding: 12px 45px;
    font-size: 18px;
  }
}

@media screen and (min-width: 1000px) {
  .app {

  }
 
  .app_wrapper {
    display: flex;
    max-width: 100%;
  }

  .cover {
    max-width: 500px;
  }

  .info {
    padding: 0;
    padding: 30px 20px;
    min-width: 350px;
  }
}

.background {
  
}

// @media screen and (min-width: 500px) {
//   .info {
//     padding: 30px 70px;
//   }
// }

// @media screen and (min-width: 640px) {
//   .cover {
//     display: flex;

//   }
// }

footer {
  display: flex;
  justify-content: center;
  font-size: 13px;
  background: #171f26;
  width: 100%;
  padding: 20px;
  position: fixed;
  bottom: 0;

  * {
    color: #4a555f;
    padding: 0 10px;
  }
}